import { Auth, Hub } from 'aws-amplify'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../app/hooks'
import { reset } from '../../calendar/calendarSlice'
import {
  setIsAdmin,
  setIsAuthenticated,
  setIsAuthenticating,
} from '../authSlice'

const useAuth = () => {
  const dispatch = useAppDispatch()
  const nav = useNavigate()

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()

        if (user) {
          dispatch(setIsAuthenticated(true))

          // Check if the user is an admin
          if (
            user?.signInUserSession?.accessToken?.payload?.[
              'cognito:groups'
            ]?.includes('admin')
          ) {
            dispatch(setIsAdmin(true))
          } else {
            dispatch(setIsAdmin(false))
          }
        } else {
          dispatch(setIsAuthenticated(false))
        }
      } catch (err) {
        dispatch(setIsAuthenticated(false))
        dispatch(setIsAdmin(false))
      }
    }

    const listenToHub = async (action: any) => {
      const event = action?.payload?.event

      switch (event) {
        case 'signIn': {
          dispatch(setIsAuthenticated(true))
          // Check if the user is an admin
          const user = action?.payload?.data
          const groups =
            user?.signInUserSession?.accessToken?.payload?.['cognito:groups']
          if (groups && groups.includes('admin')) {
            dispatch(setIsAdmin(true))
          } else {
            dispatch(setIsAdmin(false))
          }

          dispatch(setIsAuthenticating(false))

          nav('/', { replace: true })
          break
        }
        case 'confirmSignUp': {
          dispatch(setIsAuthenticated(true))
          checkAuth()
          break
        }
        case 'signOut': {
          dispatch(setIsAuthenticated(false))
          dispatch(setIsAdmin(false))
          dispatch(reset())
          break
        }
        default:
          checkAuth()
          break
      }

      // console.log(`hub listener: `, action)
    }

    checkAuth()

    Hub.listen('auth', listenToHub)
  }, [dispatch, nav])
}

export default useAuth
