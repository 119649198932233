import { useEffect } from 'react'
import Timesheet from '../features/timesheet/Timesheet'
import { useDispatch } from 'react-redux'
import { FetchAllTimesheets } from '../features/timesheet/timesheetSlice'

const History = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(FetchAllTimesheets())
  }, [dispatch])

  return (
    <div>
      <h2 className="text-center">Submitted Timesheets</h2>
      <Timesheet />
    </div>
  )
}

export default History
