import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../../components/profile/Avatar'
import useRequireAuthentication from '../../features/auth/hooks/useRequireAuthentication'

const Home = () => {
  useRequireAuthentication()
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    let isMounted = true
    const FetchEmail = async () => {
      const user = await Auth.currentAuthenticatedUser()

      if (isMounted) {
        setEmail(user?.attributes?.email)
      }
    }

    FetchEmail()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="flex h-full flex-col justify-start gap-8">
      <div className="mx-auto grid gap-2">
        <Avatar />
        {email && <p>{email}</p>}
      </div>
      <div className="mx-auto box-border grid h-24 grid-flow-col gap-2">
        <Link
          to="/user/profile"
          className="my-auto border px-6 py-4 text-center  shadow-md"
        >
          Edit Profile
        </Link>

        <Link
          to="/logout"
          className="my-auto border px-6 py-4 text-center  shadow-md"
        >
          Logout
        </Link>

        <Link
          to="/auth/delete"
          className="my-auto border px-6 py-4 text-center  shadow-md"
        >
          Delete
        </Link>
      </div>
    </div>
  )
}

export default Home
