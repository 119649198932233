import { useEffect, useState } from 'react'
import { getUsers } from '../../api/admin/get'
import useRequireAdmin from '../../features/auth/hooks/useRequireAdmin'

const Users = () => {
  useRequireAdmin()
  const [userList, setUserList] = useState<any[]>([])
  const [pagination, setPagination] = useState<string>('')
  const [limit, setLimit] = useState<number>(1)

  useEffect(() => {
    const tryFetch = async () => {
      try {
        const data = await getUsers(pagination, limit)

        if (data) {
          if (data?.Users) {
            setUserList(data.Users)
          }
          if (data?.PaginationToken) {
            setPagination(data.PaginationToken)
          } else {
            setPagination('')
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    tryFetch()
  }, [limit, pagination])

  const handleFetchMore = async () => {
    try {
      const data = await getUsers(pagination, limit)
      if (data) {
        if (data?.Users) {
          setUserList([...userList, ...data.Users])
        }
        if (data?.PaginationToken) {
          setPagination(data.PaginationToken)
        } else {
          setPagination('')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <h2>User Management</h2>
      <div className="grid grid-cols-2 gap-2 p-2">
        <div className="col-span-2 grid grid-cols-2 gap-4 border-b">
          <p>ID</p>
          <p>Email</p>
        </div>
        {userList.map((user: any) => {
          const sub = user?.Attributes?.find((attr: any) => attr.Name === 'sub')
          const email = user?.Attributes?.find(
            (attr: any) => attr.Name === 'email'
          )
          return (
            <div className="col-span-2 grid grid-cols-2" key={user.Username}>
              <p>{sub?.Value}</p>
              <p>{email?.Value}</p>
            </div>
          )
        })}
      </div>
      <div className="flex flex-row gap-2">
        {pagination && (
          <button
            onClick={handleFetchMore}
            className="border px-6 py-4 shadow-md"
          >
            Fetch More
          </button>
        )}
        <div className="mr-auto flex flex-row justify-center gap-4 text-center align-middle">
          <span className="place-self-center text-center align-middle">
            Limit per fetch:
          </span>
          <button className="px-4 py-2" onClick={() => setLimit(1)}>
            1
          </button>
          <button className="px-4 py-2" onClick={() => setLimit(2)}>
            2
          </button>
          <button className="px-4 py-2" onClick={() => setLimit(3)}>
            3
          </button>
          <button className="px-4 py-2" onClick={() => setLimit(10)}>
            10
          </button>
        </div>
      </div>
    </>
  )
}

export default Users
