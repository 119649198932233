import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../../components/Spinner'
import { setIsAuthenticated } from '../authSlice'

const VerifyAttribute = ({
  username,
  AttributeName,
  DeliveryMedium,
  Destination,
}: {
  username: string
  AttributeName: string
  DeliveryMedium: 'SMS' | 'EMAIL'
  Destination: string
}) => {
  const [verificationCode, setVerificationCode] = useState<string>('')
  const nav = useNavigate()
  const dispatch = useDispatch()

  // useEffect(() => {
  //   console.log('Verify Attribute')
  //   console.log(username) // ryalane2@hotmail.com
  //   console.log(AttributeName) // email
  //   console.log(DeliveryMedium) // EMAIL
  //   console.log(Destination) // r***@h***
  // }, [AttributeName, DeliveryMedium, Destination, username])

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      const response = await Auth.confirmSignUp(username, verificationCode)

      if (response === 'SUCCESS') {
        toast(
          'Account Created! Please fill in your profile to enable Timesheet Submissions',
          {
            type: 'success',
            position: 'bottom-center',
          }
        )
        dispatch(setIsAuthenticated(true))
        nav('/login')
      }
    } catch (err: any) {
      console.log('error confirming attribute: ', err)
      toast(err?.message, {
        type: 'error',
        position: 'bottom-center',
        autoClose: 10000,
      })
    }
  }

  return (
    <div className="mx-auto my-auto flex w-96 flex-col gap-4 rounded-md border p-2 text-center">
      <h2>Verify your {AttributeName}</h2>
      <p>
        We sent a code by {DeliveryMedium === 'EMAIL' ? 'Email' : 'SMS'} to{' '}
        {Destination}
      </p>
      <p>Please enter it below to confirm your account</p>
      <form className="flex flex-col gap-2 text-center">
        <div className="flex flex-col text-left">
          <label htmlFor="verification">Verification Code</label>
          <input
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            name="verification"
            type="verification"
            className="rounded-md border p-2 accent-tik-400 dark:text-slate-900"
          />
        </div>
        <div>
          <button
            onClick={handleSubmit}
            type="submit"
            className="rounded-md border px-6 py-4 shadow-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default VerifyAttribute
