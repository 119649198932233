import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { selectIsAuthenticated } from '../authSlice'

const useRequireAuthentication = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const nav = useNavigate()

  useEffect(() => {
    const tryAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser()
      } catch (err) {
        nav('/', { replace: true })
      }
    }

    if (!isAuthenticated) {
      tryAuth()
    }
  }, [isAuthenticated, nav])
}

export default useRequireAuthentication
