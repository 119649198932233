import { Get, Post, Delete } from '../api/days'
import fromDateStamp from '../utils/dates/fromDateStamp'
import toDateStamp from '../utils/dates/toDateStamp'
import toLocalDate from '../utils/dates/toLocalDate'

export type DayType = {
  id: string
  sortKey?: string
  userId?: string
  createdAt: number
  updatedAt: number
  date: number
  hours?: number
  notes?: string
  isSick?: boolean
  isVacation?: boolean
  isHoliday?: boolean
}

export type ServerDayType = {
  id: string
  sortKey?: string
  userId?: string
  createdAt: number
  updatedAt: number
  date: string
  hours?: number
  notes?: string
  isSick?: boolean
  isVacation?: boolean
  isHoliday?: boolean
}

export const Day = {
  findByDate: async (date: Date) => {
    // Fetch by date
    if (date !== null) {
      const response = await Get.get(date)
      return response.data
    } else {
      return null
    }
  },
  findAll: async (): Promise<DayType[]> => {
    // Fetch all days
    const days: ServerDayType[] = await Get.getAll()

    // Convert to DayType
    const daysWithIds: DayType[] = days.map((day) => {
      return {
        ...day,
        id: day.id,
        sortKey: day.sortKey,
        userId: day.userId,
        createdAt: day.createdAt,
        updatedAt: day.updatedAt,
        date: +toLocalDate(fromDateStamp(day.date)),
        hours: day.hours,
        notes: day.notes,
        isSick: day.isSick,
        isVacation: day.isVacation,
        isHoliday: day.isHoliday,
      }
    })

    return daysWithIds
  },
  update: async (day: Partial<DayType>) => {
    if (day.date !== null && day.date !== undefined) {
      let serverDay: Partial<ServerDayType> = {
        ...day,
        date: toDateStamp(new Date(day.date)),
      }
      const response: ServerDayType = await Post.post(serverDay)

      return response
    }
    // Post a new day/update a day
  },
  submit: async (day: Day, params: any) => {
    // Post a new day
  },
  delete: async (id: string) => {
    // Delete a day
    const response = await Delete.deleteDay(id)

    return response
  },
}
