import { useEffect, useState } from 'react'
import { useIsMounted } from 'usehooks-ts'

// TODO: Handle unmounted set state error
const useTimedDisable = (delay: number = 3000): [boolean, () => void] => {
  const [disabled, setDisabled] = useState(false)
  const isMounted = useIsMounted()

  const Disable = () => {
    setDisabled(true)
    setTimeout(() => {
      if (isMounted()) {
        setDisabled(false)
      }
    }, delay)
  }

  return [disabled, Disable]
}

export default useTimedDisable
