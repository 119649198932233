import { NavLink } from 'react-router-dom'
import logo from '../../assets/img/TikLogo.png'
import {
  AiFillHome,
  AiFillLock,
  AiOutlineHistory,
  AiOutlineLogin,
  AiOutlineSend,
  AiOutlineUser,
  AiOutlineMenu,
  AiFillCalendar,
} from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import {
  selectIsAdmin,
  selectIsAuthenticated,
} from '../../features/auth/authSlice'
import { useAppSelector } from '../../app/hooks'
import { ToolTip } from '../Tooltip'

const Navigation = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const isAdmin = useAppSelector(selectIsAdmin)
  const activeClass = 'text-tik-500 text-center w-full h-full'
  const inactiveClass =
    'text-slate-500 dark:text-slate-400 text-center w-full h-full'

  return (
    <nav className="fixed bottom-0 flex h-20 w-full flex-grow-0 flex-row bg-white text-center shadow-md shadow-black dark:bg-slate-800 md:relative md:bottom-auto md:h-screen md:max-h-screen md:w-20 md:flex-shrink-0 md:flex-col">
      <div className="mx-auto">
        <ul className="flex h-20 w-full flex-grow-0 flex-row justify-center gap-4 text-center md:h-auto md:flex-col">
          <li className="hidden h-20 w-20 flex-grow-0 select-none border-b p-4 md:visible md:flex md:h-full md:flex-shrink-0">
            <img src={logo} alt="Tik Logo" />
          </li>
          <li className="h-20 w-20 md:h-full">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClass : inactiveClass
              }
              to="/"
              data-tip="Home"
            >
              <AiFillHome className="h-full w-full p-4 px-6" />
            </NavLink>
          </li>
          <li className="h-20 w-20 md:h-full">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClass : inactiveClass
              }
              to="/calendar"
              data-tip="Calendar"
            >
              <AiFillCalendar className="h-full w-full p-4 px-6" />
            </NavLink>
          </li>
          {isAuthenticated ? (
            <>
              <li className="w-20 md:h-full">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? activeClass : inactiveClass
                  }
                  to="/submit"
                  data-tip="Submit"
                >
                  <AiOutlineSend className="h-full w-full p-4 px-6" />
                </NavLink>
              </li>
              <li className="w-20 md:h-full">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? activeClass : inactiveClass
                  }
                  to="/history"
                  data-tip="History"
                >
                  <AiOutlineHistory className="h-full w-full p-4 px-6" />
                </NavLink>
              </li>
              <li className="w-20 md:h-full">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? activeClass : inactiveClass
                  }
                  to="/user"
                  data-tip="User"
                >
                  <AiOutlineUser className="h-full w-full p-4 px-6" />
                </NavLink>
              </li>
              <ToolTip />
            </>
          ) : (
            <li className="w-20 md:h-full">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeClass : inactiveClass
                }
                to="/login"
                data-tip="Login"
              >
                <AiOutlineLogin className="h-full w-full p-4 px-6" />
              </NavLink>
              <ToolTip />
            </li>
          )}
          {isAdmin && (
            <li className="w-20 md:h-full">
              <NavLink
                className={({ isActive }) =>
                  isActive ? activeClass : inactiveClass
                }
                to="/admin"
                data-tip="Admin"
              >
                <AiFillLock className="h-full w-full p-4 px-6" />
              </NavLink>
              <ToolTip />
            </li>
          )}
        </ul>
      </div>
      <ToolTip />
    </nav>
  )
}

export default Navigation
