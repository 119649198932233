import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner'

const Delete = () => {
  const nav = useNavigate()

  useEffect(() => {
    // const tryLogout = async () => {
    //   try {
    //     return await Auth.signOut()
    //   } catch (err) {
    //     console.log('error signing out: ', err)
    //   }
    // }
    // toast.promise(
    //   tryLogout,
    //   {
    //     pending: {
    //       render: () => 'Logging out...',
    //       type: 'info',
    //       icon: <Spinner />,
    //     },
    //     success: {
    //       render: () => 'Logged out',
    //       type: 'success',
    //       icon: <AiOutlineCheckCircle className="text-tik-500" />,
    //     },
    //     error: {
    //       render: () => 'Error logging out',
    //       type: 'error',
    //       icon: <AiOutlineCloseCircle className="text-red-500" />,
    //     },
    //   },
    //   {
    //     position: 'bottom-center',
    //   }
    // )
    // nav('/login')
  }, [nav])

  const handleSubmit = () => {
    const tryDelete = async () => {
      try {
        return await Auth.deleteUser()
      } catch (err) {
        console.log('error deleting user: ', err)
      }
    }

    toast.promise(
      tryDelete,
      {
        pending: {
          render: () => 'Deleting user...',
          type: 'info',
          icon: <Spinner />,
        },
        success: {
          render: () => 'User deleted',
          type: 'success',
          icon: <AiOutlineCheckCircle className="text-tik-500" />,
        },
        error: {
          render: () => 'Error deleting user',
          type: 'error',
          icon: <AiOutlineCloseCircle className="text-red-500" />,
        },
      },
      {
        position: 'bottom-center',
      }
    )
    nav('/login')
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <h2 className="text-center leading-3">
          You are about to delete your account.
        </h2>
        <h2 className="text-center leading-3">This cannot be undone.</h2>
        <h2 className="text-center leading-3">Are you sure?</h2>

        <div className="flex justify-center gap-4">
          <button
            className="rounded bg-red-500 py-2 px-4 font-bold text-white shadow-sm hover:bg-red-700"
            onClick={handleSubmit}
          >
            Delete
          </button>
          <button
            className="rounded bg-gray-500 py-2 px-4 font-bold text-white shadow-sm hover:bg-gray-700"
            onClick={() => nav('/')}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}

export default Delete
