import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { selectIsAdmin } from '../authSlice'
import useRequireAuthentication from './useRequireAuthentication'

const useRequireAdmin = () => {
  useRequireAuthentication()
  const isAdmin = useAppSelector(selectIsAdmin)
  const nav = useNavigate()

  useEffect(() => {
    const tryAuth = async () => {
      try {
        // Check if the user has a cognito:groups admin claim
        const user = await Auth.currentAuthenticatedUser()

        if (
          !user?.signInUserSession?.accessToken?.payload?.[
            'cognito:groups'
          ]?.includes('admin')
        ) {
          nav('/', { replace: true })
        }
      } catch (err) {
        nav('/', { replace: true })
      }
    }

    if (!isAdmin) {
      tryAuth()
    }
  }, [isAdmin, nav])
}

export default useRequireAdmin
