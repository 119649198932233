import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import startOfDay from 'date-fns/startOfDay'
import addDays from 'date-fns/addDays'
import addWeeks from 'date-fns/addWeeks'
import addMonths from 'date-fns/addMonths'
import subDays from 'date-fns/subDays'
import subWeeks from 'date-fns/subWeeks'
import subMonths from 'date-fns/subMonths'
import { addYears, subYears } from 'date-fns'
import { Day, DayType, ServerDayType } from '../../model/Day'
import toLocalDate from '../../utils/dates/toLocalDate'
import fromDateStamp from '../../utils/dates/fromDateStamp'

const DayAdapter = createEntityAdapter({
  selectId: (day: DayType) => day.id,
  sortComparer: (a: DayType, b: DayType) => {
    return +a.date > +b.date ? 1 : -1
  },
})

export interface CalendarState {
  calendarDate: number
  selectedDates: number[]
  isSelectMultiple: boolean
  isLoading: boolean
  individualDaysLoading: number[]
}

const initialState: CalendarState = {
  calendarDate: +new Date(),
  selectedDates: [] as number[],
  isSelectMultiple: false,
  isLoading: false,
  individualDaysLoading: [] as number[],
}

export const FetchAllDays = createAsyncThunk('fetchAllDays', async () => {
  let response = await Day.findAll()

  // Filter out anything without an ID
  response = response.filter((day) => day?.id)

  return response
})

export const SaveSelectedDays = createAsyncThunk(
  'saveSelectedDays',
  async (value: any, { getState }): Promise<ServerDayType[]> => {
    const selectedDates: number[] = (getState() as RootState).calendar
      .selectedDates
    const selectedDays: DayType[] = daySelectors
      .selectAll(getState() as RootState)
      .filter((item) => selectedDates.includes(+item.date))

    const requests = selectedDates.map(async (item) => {
      let day = selectedDays.find((day) => day.date === item)

      let hours = !isNaN(+value) ? +value : undefined
      let isSick = isNaN(+value) && value?.toLowerCase() === 'sick' // no toLowerCase on number
      let isVacation = isNaN(+value) && value?.toLowerCase() === 'vacation' // no toLowerCase on number
      if (value === null || value === undefined) {
        // If no value is provided, default to 0 hours
        hours = 0
      }

      // If a day already exists, update and submit
      if (day) {
        day = {
          ...day,
          hours,
          isSick,
          isVacation,
        }
        return await Day.update(day)
      }

      // Create a new day entirely
      return await Day.update({
        date: item,
        hours,
        isSick,
        isVacation,
      })
    })

    const response = await Promise.all(requests)
    // @ts-ignore
    return response
  }
)

export const DeleteSelectedDays = createAsyncThunk(
  'deleteSelectedDays',
  async (_, { getState }): Promise<any> => {
    const selectedDates: number[] = (getState() as RootState).calendar
      .selectedDates
    const selectedDays = daySelectors
      .selectAll(getState() as RootState)
      .filter((day) => selectedDates.includes(+day.date))

    const requests = selectedDays.map(async (item) => {
      return await Day.delete(item.id)
    })
    // const requests = selectedDates.map(async (item) => {
    //   return await Day.delete({
    //     id: item,
    //     date: item,
    //   })
    // })
    await Promise.all(requests)

    return selectedDays
  }
)

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: DayAdapter.getInitialState(initialState),
  reducers: {
    setDate: (state, action: PayloadAction<number>) => {
      state.calendarDate = action.payload
    },
    nextDay: (state) => {
      state.calendarDate = +startOfDay(
        addDays(+new Date(state.calendarDate), 1)
      )
    },
    nextWeek: (state) => {
      state.calendarDate = +startOfDay(
        addWeeks(+new Date(state.calendarDate), 1)
      )
    },
    nextMonth: (state) => {
      state.calendarDate = +startOfDay(
        addMonths(+new Date(state.calendarDate), 1)
      )
    },
    nextYear: (state) => {
      state.calendarDate = +startOfDay(
        addYears(+new Date(state.calendarDate), 1)
      )
    },
    prevDay: (state) => {
      state.calendarDate = +startOfDay(
        subDays(+new Date(state.calendarDate), 1)
      )
    },
    prevWeek: (state) => {
      state.calendarDate = +startOfDay(
        subWeeks(+new Date(state.calendarDate), 1)
      )
    },
    prevMonth: (state) => {
      state.calendarDate = +startOfDay(
        subMonths(+new Date(state.calendarDate), 1)
      )
    },
    prevYear: (state) => {
      state.calendarDate = +startOfDay(
        subYears(+new Date(state.calendarDate), 1)
      )
    },

    selectDate: (state, action: PayloadAction<number>) => {
      if (state.isSelectMultiple) {
        // Check if it already exists within the state
        const index = state.selectedDates.findIndex(
          (date) => date === action.payload
        )
        if (index === -1) {
          state.selectedDates.push(action.payload)
        } else {
          state.selectedDates.splice(index, 1)
        }
      } else {
        if (state.selectedDates[0] === action.payload) {
          state.selectedDates = []
        } else {
          state.selectedDates[0] = action.payload
        }
      }
    },

    toggleSelectMultiple: (state) => {
      if (state.isSelectMultiple) {
        state.selectedDates = []
      }
      state.isSelectMultiple = !state.isSelectMultiple
    },

    setIndividualDaysLoading: (state, action: PayloadAction<boolean>) => {
      state.individualDaysLoading = state.selectedDates
    },
    reset: (state) => {
      state.calendarDate = +new Date()
      state.selectedDates = []
      state.isSelectMultiple = false
      state.isLoading = false
      state.individualDaysLoading = []
      DayAdapter.removeAll(state)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchAllDays.pending, (state) => {
        state.isLoading = true
      })
      .addCase(FetchAllDays.fulfilled, (state, action) => {
        state.isLoading = false
        // Map the date to the toValue
        let days = [...action.payload]
          .filter((item) => item?.id)
          .map((item) => ({
            ...item,
            date: +item.date ?? item.date,
          }))

        DayAdapter.setMany(state, days)
        // state.days = action.payload.map((item: DayType) => {
        //   return {
        //     ...item,
        //     date: +new Date(item.date),
        //   }
        // })
        // state.value += action.payload;
      })
      .addCase(FetchAllDays.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(SaveSelectedDays.pending, (state) => {
        state.isLoading = true
        state.individualDaysLoading = state.selectedDates
      })
      .addCase(SaveSelectedDays.fulfilled, (state, action) => {
        state.isLoading = false
        // console.log(action.payload)
        let days = [...action.payload].map((item) => ({
          ...item,
          date: +toLocalDate(fromDateStamp(item.date)),
        }))
        days = days.filter((item) => item.id !== null && item.id !== undefined)

        DayAdapter.setMany(state, days)
      })
      .addCase(SaveSelectedDays.rejected, (state) => {
        state.isLoading = false
        state.individualDaysLoading = []
      })
      .addCase(DeleteSelectedDays.pending, (state) => {
        state.isLoading = true
      })
      .addCase(DeleteSelectedDays.fulfilled, (state, action) => {
        state.isLoading = false
        if (action?.payload) {
          const days = action?.payload
          const dayIds = days.map((item: any) => item.id)

          DayAdapter.removeMany(state, dayIds)
        }
      })
      .addCase(DeleteSelectedDays.rejected, (state) => {
        state.isLoading = false
        console.log(`delete failed`)
      })
    // Loop through existing days
    // If one of the new days is in that list then replace the old one
    // Loop through the new days, if they are in the existing days, remove them
    // Merge arrays
    // let days: DayType[] = [...current(state).days]
    // console.log(`starting days`, days)
    // // Update the days with the new values
    // days = days.map((oldDay: DayType) => {
    //   let newDay = action.payload.find(
    //     (newDay: DayType) => newDay.date === oldDay.date
    //   )
    //   if (newDay) {
    //     return newDay
    //   }
    //   return oldDay
    // })
    // let newDays: DayType[] = [...action.payload]
    // newDays = newDays.filter((newDay: DayType) => {
    //   let oldDay = days.find(
    //     (oldDay: DayType) => oldDay.date === newDay.date
    //   )
    //   if (oldDay) {
    //     return false
    //   }
    // })
    // console.log(`payload`, action.payload)
    // console.log(`days`, days)
    // days = [...days, ...newDays]
    // state.days = [...days, ...newDays]
    // })
    // .addCase(SaveSelectedDays.rejected, (state) => {
    //   state.isLoading = false
    // })
  },
})

export const {
  setDate,
  nextDay,
  nextWeek,
  nextMonth,
  nextYear,
  prevDay,
  prevWeek,
  prevMonth,
  prevYear,
  selectDate,
  toggleSelectMultiple,
  reset,
} = calendarSlice.actions

export const selectCalendarDate = (state: RootState) =>
  state.calendar.calendarDate
export const selectSelectedDate = (state: RootState) =>
  state.calendar.selectedDates
export const selectIsSelectMultiple = (state: RootState) =>
  state.calendar.isSelectMultiple
export const isCalendarLoading = (state: RootState) => state.calendar.isLoading

export const daySelectors = DayAdapter.getSelectors(
  (state: RootState) => state.calendar
)
// export const getAllDays = () => daySelectors.selectAll(daySelectors.getState())
// export const daySelectors = DayAdapter.getSelectors()

export default calendarSlice.reducer
