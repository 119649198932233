import useWeekDays from '../../../hooks/dates/useWeekDays'

const WeekDays = () => {
  const weekDates = useWeekDays()

  return (
    <div className="grid grid-cols-7">
      {weekDates.map((item: Date, idx: number) => {
        return (
          <abbr
            key={`weekday-${idx.toString()}`}
            title={item.toLocaleDateString(undefined, { weekday: 'long' })}
          >
            {item.toLocaleDateString(undefined, { weekday: 'short' })}
          </abbr>
        )
      })}
    </div>
  )
}
export default WeekDays
