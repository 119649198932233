import { DayType } from '../../../model/Day'

const GetDayDetails = (days: DayType[], day: Date): DayType => {
  let dayDetails = days.find((dayDetail: DayType) => +dayDetail.date === +day)

  if (!dayDetails) {
    dayDetails = {
      date: +day,
      hours: 0,
      isSick: false,
      isVacation: false,
      createdAt: +new Date(),
      updatedAt: +new Date(),
      id: 'fake-id',
    }
  }

  return dayDetails
}

export default GetDayDetails
