import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  min,
  startOfMonth,
} from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
  ZohoWebhookPeriodDateType,
  ZohoWebhookPeriodType,
  ZohoWebhookType,
} from '../../api/zoho/post'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  daySelectors,
  FetchAllDays,
  selectCalendarDate,
} from '../../features/calendar/calendarSlice'
import Nav from '../../features/calendar/components/Nav'
import SubmitTimesheetButton from '../../features/timesheet/components/SubmitTimesheetButton'
import useSendTimesheetToZoho from '../../features/timesheet/hooks/useSendTimesheetToZoho'
import {
  SubmitTimesheet,
  TimesheetType,
} from '../../features/timesheet/timesheetSlice'
import useWeekDays from '../../hooks/dates/useWeekDays'
import { DayType } from '../../model/Day'

const TimesheetHeaders = () => {
  const weekDays = useWeekDays()
  return (
    <div className="flex flex-col bg-slate-100 dark:bg-slate-800">
      <div className="grid-grid-cols-8 mt-2 grid grid-flow-col items-center justify-around text-center">
        <Nav />
      </div>
      <div className="grid grid-cols-8 py-4 text-center">
        <div>
          <h2>Week Starting</h2>
        </div>
        {weekDays.map((item: Date, idx: number) => {
          return (
            <abbr
              key={`weekday-${idx.toString()}`}
              title={item.toLocaleDateString(undefined, { weekday: 'long' })}
            >
              {item.toLocaleDateString(undefined, { weekday: 'short' })}
            </abbr>
          )
        })}
      </div>
    </div>
  )
}

const TimesheetPreview = ({
  start,
  shouldSubmit,
  isSelected,
  handleSelect,
}: {
  start: Date
  shouldSubmit: boolean
  isSelected: boolean
  handleSelect: (day: Date) => void
}) => {
  const dispatch = useDispatch()
  const days = useAppSelector(daySelectors.selectAll)
  const [dayInfo, setDayInfo] = useState<any[]>([])

  useEffect(() => {
    dispatch(FetchAllDays())
  }, [dispatch])

  useEffect(() => {
    const startsOnMonday = 1
    const dayInfoList = eachDayOfInterval({
      start,
      end: endOfWeek(start, { weekStartsOn: startsOnMonday }),
    })

    let dayDetails = dayInfoList.map((day: Date) => {
      const dayDetails = days.find(
        (dayDetail: DayType) => +dayDetail.date === +day
      )

      if (dayDetails) {
        return dayDetails?.hours
          ? dayDetails.hours
          : dayDetails?.isSick
          ? 'Sick'
          : dayDetails?.isVacation
          ? 'Vacation'
          : '-'
      } else {
        return '-'
      }
    })

    setDayInfo(dayDetails)
  }, [start, days])

  const onSelect = () => {
    handleSelect(start)
  }

  return (
    <div
      className={`col-span-8 grid cursor-pointer grid-cols-8 items-center py-4 text-center align-middle ${
        isSelected
          ? 'odd:bg-tik-500/50 even:bg-tik-400/50'
          : 'odd:bg-slate-100 dark:odd:bg-slate-800'
      }`}
      onClick={onSelect}
    >
      <p>
        {start.toLocaleDateString(undefined, {
          day: '2-digit',
          weekday: undefined,
          month: 'short',
        })}
      </p>
      {/* Random number between 1 and 8 */}
      {dayInfo.map((item: any, idx: number) => {
        return (
          <div key={`day-${idx.toString()}`}>
            <p>{item}</p>
          </div>
        )
      })}
    </div>
  )
}

const Submit = () => {
  const [weeks, setWeeks] = useState<Date[]>([])
  const [shouldSubmitToZoho, setShouldSubmitToZoho] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState<number>(+new Date())
  const [selectedTimesheets, setSelectedTimesheets] = useState<Date[]>([])
  const calendarDate = useAppSelector(selectCalendarDate)
  const days = useAppSelector(daySelectors.selectAll)
  const dispatch = useAppDispatch()
  const submitToZoho = useSendTimesheetToZoho()

  useEffect(() => {
    // Reset selected timesheets on month change
    setSelectedTimesheets([])
  }, [calendarDate])

  useEffect(() => {
    let date = new Date(calendarDate)
    const start = startOfMonth(date)
    const end = endOfMonth(date)
    const weekDayStart = 1 // Monday
    const weekList = eachWeekOfInterval(
      { start, end },
      { weekStartsOn: weekDayStart }
    )
    setWeeks(weekList)
  }, [calendarDate])

  const handleSelect = (date: Date) => {
    let selected = [...selectedTimesheets]
    if (selected.includes(date)) {
      selected = selected.filter((item: Date) => item !== date)
    } else {
      selected.push(date)
    }
    setSelectedTimesheets(selected)
  }

  const GetDayValue = (day: DayType) => {
    if (day) {
      return day?.hours
        ? day.hours
        : day?.isSick
        ? 'Sick'
        : day?.isVacation
        ? 'Vacation'
        : '-'
    } else {
      return '-'
    }
  }

  const GetDayDetails = (day: Date): DayType => {
    let dayDetails = days.find((dayDetail: DayType) => +dayDetail.date === +day)

    if (!dayDetails) {
      dayDetails = {
        date: +day,
        hours: 0,
        isSick: false,
        isVacation: false,
        createdAt: +new Date(),
        updatedAt: +new Date(),
        id: 'fake-id',
      }
    }

    return dayDetails
  }

  const handleSubmit = () => {
    const startsOnMonday = 1
    const SubmitTimesheetToBackend = (timesheet: TimesheetType) => {
      let duration: number = timesheet?.days?.length || 0
      dispatch(SubmitTimesheet({ start: timesheet.start, duration }))

      toast(
        `Timesheet submitted for ${new Date(
          timesheet.start
        ).toLocaleDateString()}`,
        {
          position: 'bottom-center',
          type: 'success',
        }
      )

      // Reset the selected timesheets
      setSelectedTimesheets([] as Date[])
    }
    const SubmitTimesheetToZoho = (timesheets: TimesheetType[]) => {
      let fullDayHours = 7.5

      const convertToZohoDay = (day: DayType): ZohoWebhookPeriodDateType => {
        let title = day?.hours
          ? day.hours + ''
          : day?.isSick
          ? 'Sick'
          : day?.isVacation
          ? 'Vacation'
          : '-'

        return {
          title,
          date: new Date(day.date).toISOString(),
          valid: true,
        }
      }

      let periods: ZohoWebhookPeriodType[] = [] as ZohoWebhookPeriodType[]
      let periodHours = [] as number[]
      let periodDays = [] as number[]
      timesheets.forEach((timesheet: TimesheetType) => {
        let zohoDays: ZohoWebhookPeriodDateType[] =
          timesheet!.days!.map(convertToZohoDay)
        const totalHours = timesheet!.days!.reduce(
          (acc: number, day: DayType) => {
            return acc + (day?.hours || 0)
          },
          0
        )
        const totalDays = +(totalHours / fullDayHours).toFixed(2)

        let zohoPeriod: ZohoWebhookPeriodType = {
          startingDate: format(new Date(timesheet.start), 'MM/dd/yyyy'),
          notes: '',
          totalDays,
          totalHours,
          dates: zohoDays,
        }

        periods.push(zohoPeriod)
        periodHours.push(totalHours)
        periodDays.push(totalDays)
      })

      let firstDate = min(
        timesheets.map((timesheet: TimesheetType) => {
          return new Date(timesheet.start)
        })
      )
      let totalDays = periodDays.reduce((acc: number, day: number) => {
        return acc + day
      }, 0)
      let totalHours = periodHours.reduce((acc: number, hour: number) => {
        return acc + hour
      }, 0)

      const zohoDetails: {
        firstDate: string
        periods: any[]
        totalDays: number
        totalHours: number
      } = {
        firstDate: format(firstDate, 'MM/dd/yyyy'),
        periods,
        totalDays,
        totalHours,
      }

      toast.promise(
        submitToZoho(zohoDetails),
        {
          pending: 'Submitting to Zoho...',
          success: 'Timesheet submitted to Zoho',
          error: 'Error submitting to Zoho',
        },
        {
          position: 'bottom-center',
        }
      )
    }

    const BuildTimesheet = (start: Date): TimesheetType => {
      let timesheet = { id: 'test' } as TimesheetType
      const end = endOfWeek(start, { weekStartsOn: startsOnMonday })
      const days = eachDayOfInterval({ start, end })

      // Get the days stored in the state for the interval
      const daysInState: DayType[] = days.map(GetDayDetails)

      timesheet.start = +start
      timesheet.end = +end
      timesheet.days = daysInState

      return timesheet
    }

    // Make sure the user has selected timesheets to submit
    if (selectedTimesheets.length > 0) {
      let timesheets: TimesheetType[] = [] as TimesheetType[]

      // Build the timesheets
      selectedTimesheets.forEach((item: Date) => {
        timesheets.push(BuildTimesheet(item))
      })

      if (shouldSubmitToZoho) {
        // Build details for zoho
        SubmitTimesheetToZoho(timesheets)
      }

      // Submit the timesheets
      timesheets.forEach((item: TimesheetType) => {
        SubmitTimesheetToBackend(item)
      })
    } else {
      toast('Please select timesheets to submit', {
        position: 'bottom-center',
        type: 'info',
      })
    }
  }

  return (
    <>
      <div className="gap mx-auto grid w-full grid-cols-8 text-center text-xs md:text-sm lg:text-base">
        <div className="col-span-8">
          <TimesheetHeaders />
        </div>
        {weeks.map((week: Date, idx: number) => {
          const isSelected = selectedTimesheets.includes(week)
          return (
            <TimesheetPreview
              isSelected={isSelected}
              handleSelect={handleSelect}
              start={week}
              key={week.toString()}
              shouldSubmit={shouldSubmitToZoho}
            />
          )
        })}
      </div>
      <div className="mx-auto my-3 max-w-sm text-center">
        <p className="my-2">
          Submitting {selectedTimesheets.length}{' '}
          {selectedTimesheets.length !== 1 ? 'weeks' : 'week'}
        </p>
        <button
          onClick={handleSubmit}
          // disabled={selectedTimesheets.length === 0}
          className={`rounded-sm border-0 bg-tik-400 py-2 px-6 text-lg font-semibold text-slate-900 shadow-md shadow-slate-900/20 ${
            selectedTimesheets.length === 0 ? 'bg-gray-300' : ''
          }`}
        >
          Submit
        </button>
      </div>
      <div>
        <label htmlFor="submit-to-zoho" className="align-middle">
          (Debug) Enable Submit To Zoho
        </label>
        <input
          type="checkbox"
          id="submit-to-zoho"
          onChange={(e) => setShouldSubmitToZoho(e.target.checked)}
        />
      </div>
    </>
  )
}

export default Submit
