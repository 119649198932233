const CalendarNavButton = ({
  children,
  onClick,
  onMouseOver,
  disabled,
  tooltip,
}: {
  children: any
  onClick: (e?: any) => void
  onMouseOver?: any
  disabled?: boolean
  tooltip?: string
}) => {
  return (
    <div
      className="flex h-11 w-11 select-none justify-center text-center"
      data-tip={tooltip}
    >
      <button
        className={`h-full w-full rounded-full border border-slate-100 bg-slate-200 text-center shadow-md hover:bg-slate-100 dark:border-slate-600 dark:bg-slate-900 dark:hover:bg-slate-800 ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={onClick}
        onMouseOver={onMouseOver}
      >
        {children}
      </button>
    </div>
  )
}

export default CalendarNavButton
