import useRequireAdmin from '../../features/auth/hooks/useRequireAdmin'

const Contracts = () => {
  useRequireAdmin()
  return (
    <div>
      <h1>Contracts</h1>
    </div>
  )
}

export default Contracts
