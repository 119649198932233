import format from 'date-fns/format'
import min from 'date-fns/min'
import {
  ZohoWebhookPeriodDateType,
  ZohoWebhookPeriodType,
} from '../../../api/zoho/post'
import { DayType } from '../../../model/Day'
import { TimesheetType } from '../../timesheet/timesheetSlice'
import convertToZohoDay from './ConvertToZohoDay'

const BuildZohoTimesheet = (
  timesheets: TimesheetType[]
): {
  firstDate: string
  periods: any[]
  totalDays: number
  totalHours: number
} => {
  // TODO: Extract to setting
  const fullDayHours = 7.5

  let periods: ZohoWebhookPeriodType[] = [] as ZohoWebhookPeriodType[]
  let periodHours = [] as number[]
  let periodDays = [] as number[]
  timesheets.forEach((timesheet: TimesheetType) => {
    let zohoDays: ZohoWebhookPeriodDateType[] =
      timesheet!.days!.map(convertToZohoDay)
    const totalHours = timesheet!.days!.reduce((acc: number, day: DayType) => {
      return acc + (day?.hours || 0)
    }, 0)
    const totalDays = +(totalHours / fullDayHours).toFixed(2)

    let zohoPeriod: ZohoWebhookPeriodType = {
      startingDate: format(new Date(timesheet.start), 'MM/dd/yyyy'),
      notes: '',
      totalDays,
      totalHours,
      dates: zohoDays,
    }

    periods.push(zohoPeriod)
    periodHours.push(totalHours)
    periodDays.push(totalDays)
  })

  let firstDate = min(
    timesheets.map((timesheet: TimesheetType) => {
      return new Date(timesheet.start)
    })
  )
  let totalDays = periodDays.reduce((acc: number, day: number) => {
    return acc + day
  }, 0)
  let totalHours = periodHours.reduce((acc: number, hour: number) => {
    return acc + hour
  }, 0)

  const zohoDetails: {
    firstDate: string
    periods: any[]
    totalDays: number
    totalHours: number
  } = {
    firstDate: format(firstDate, 'MM/dd/yyyy'),
    periods,
    totalDays,
    totalHours,
  }

  return zohoDetails
}

export default BuildZohoTimesheet
