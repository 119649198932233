import { Link } from 'react-router-dom'
import useRequireAdmin from '../../features/auth/hooks/useRequireAdmin'

const Home = () => {
  useRequireAdmin()
  return (
    <>
      <div className="flex flex-col gap-8">
        <h1>Admin Home</h1>
        <div>
          <Link to="/admin/users" className="border px-6 py-4 shadow-md">
            Users
          </Link>
        </div>
        <div>
          <Link to="/admin/contracts" className="border px-6 py-4 shadow-md">
            Contracts
          </Link>
        </div>
      </div>
    </>
  )
}

export default Home
