import ReactTooltip from 'react-tooltip'
import useIsMobile from '../hooks/useIsMobile'

// from: https://github.com/wwayne/react-tooltip/issues/203

export const ToolTip = () => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return null
  }

  return (
    <>
      <ReactTooltip effect="solid" delayShow={150} />
    </>
  )
}
