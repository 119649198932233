import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
// @ts-ignore
import Jdenticon from 'react-jdenticon'
import Spinner from '../Spinner'

const Avatar = () => {
  const [sub, setSub] = useState<string>('')

  useEffect(() => {
    let isMounted = true

    const FetchSub = async () => {
      const user = await Auth.currentAuthenticatedUser()

      if (isMounted) {
        if (user?.attributes?.sub) {
          setSub(user.attributes.sub)
        }
      }
    }

    FetchSub()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="m-auto mt-2 grid h-28 w-28 overflow-clip rounded-full border bg-slate-50 text-center shadow-sm">
      {sub !== '' ? <Jdenticon value={sub} size={'112'} /> : <Spinner />}
    </div>
  )
}

export default Avatar
