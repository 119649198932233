import { HubCapsule } from '@aws-amplify/core'
import { Auth, Hub } from 'aws-amplify'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { ToolTip } from '../../components/Tooltip'
import VerifyAttribute from '../../features/auth/components/VerifyAttribute'

const ErrorMessage = ({ message }: { message: any }) => {
  return (
    <div className="my-2 text-left text-sm text-red-500">
      <AiOutlineCloseCircle />
      {message}
    </div>
  )
}

const Register = () => {
  const [verificationRequired, setVerificationRequired] =
    useState<boolean>(false)
  const [verificationDetails, setVerificationDetails] = useState<any>(null)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange' })
  const password = watch('Password', '')
  const confirmPass = watch('ConfirmPassword', '')
  const email = watch('Email', '')

  // Setup a listener to change the tab back to login when the confirm user signup is done
  useEffect(() => {
    const ConfirmUserListener = async (action: HubCapsule) => {
      if (action?.payload?.event === 'confirmSignUp') {
        // Check if the response is successful
        if (action?.payload?.data?.response === 'SUCCESS') {
          // setTabIndex(0)
        } else {
          // Set the error to the response
          console.log(action.payload.data.response)
        }
      }
    }

    Hub.listen('auth', ConfirmUserListener)

    return () => Hub.remove('auth', ConfirmUserListener)
  }, [])

  const onSubmit = (e: any) => {
    e.preventDefault()
    // console.log(e)
    const tryRegister = async () => {
      try {
        const user = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
          },
        })
        if (user?.userConfirmed === false) {
          setVerificationRequired(true)
          setVerificationDetails(user?.codeDeliveryDetails)
        }
      } catch (err) {
        console.log('error signing up: ', err)
      }
    }

    toast.promise(
      tryRegister,
      {
        pending: {
          render: () => 'Signing up...',
          type: 'info',
        },
        success: {
          render:
            'Account Created! Please enter the verification code that was sent to you',
          type: 'success',
        },
        error: {
          render: (data) => 'Error signing up: ' + data,
          type: 'error',
        },
      },
      {
        position: 'bottom-center',
      }
    )
  }

  if (verificationRequired && verificationDetails) {
    return (
      <>
        <VerifyAttribute {...verificationDetails} username={email} />
      </>
    )
  }

  return (
    <>
      <div className="mx-auto my-auto flex w-96 flex-col gap-4 rounded-md border p-2 text-center">
        <h2>Create an Account</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-2 text-left"
        >
          <div className="flex flex-col">
            <label htmlFor="Email">Email</label>
            <input
              type="text"
              placeholder="Email"
              className="rounded-md border p-2 accent-tik-400 dark:text-slate-900"
              {...register('Email', { required: true, pattern: /^\S+@\S+$/i })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="Password">Password</label>
            <input
              type="password"
              placeholder="Password"
              className="rounded-md border p-2 accent-tik-400 dark:text-slate-900"
              {...register('Password', {
                required: true,
                minLength: 6,
                maxLength: 99,
                validate: (value) => value === confirmPass,
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/i,
              })}
            />
            {errors?.Password?.type === 'required' && (
              <ErrorMessage message="Password is required" />
            )}
            {errors?.Password?.type === 'minLength' && (
              <ErrorMessage message="A password must be at least 6 characters long" />
            )}
            {errors?.Password?.type === 'pattern' && (
              <ErrorMessage
                message={
                  <div>
                    Password must contain:
                    <ul className="ml-4 block list-disc">
                      <li>At least one uppercase letter</li>
                      <li>At least one lowercase letter</li>
                      <li>At least one number</li>
                      <li>At least one special character</li>
                    </ul>
                  </div>
                }
              />
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="ConfirmPassword">Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm Password"
              autoComplete="false"
              className={`rounded-md border p-2 accent-tik-400 dark:text-slate-900`}
              {...register('ConfirmPassword', {
                required: true,
                minLength: 6,
                maxLength: 99,
                validate: (value) => value === password,
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/i,
              })}
            />
            {errors?.ConfirmPassword?.type === 'required' && (
              <ErrorMessage message="Confirm Password is required" />
            )}
            {errors?.ConfirmPassword?.type === 'minLength' && (
              <ErrorMessage message="A password must be at least 6 characters long" />
            )}
            {errors?.ConfirmPassword?.type === 'pattern' && (
              <ErrorMessage
                message={
                  <div>
                    Password must contain:
                    <ul className="ml-4 block list-disc">
                      <li>At least one uppercase letter</li>
                      <li>At least one lowercase letter</li>
                      <li>At least one number</li>
                      <li>At least one special character</li>
                    </ul>
                  </div>
                }
              />
            )}
            {errors?.ConfirmPassword?.type === 'validate' && (
              <ErrorMessage message="Passwords do not match" />
            )}
          </div>
          <button
            className={`mx-auto max-w-max rounded-md border px-6 py-4 font-semibold shadow-md ${
              isValid && isDirty
                ? 'cursor-pointer bg-tik-400 text-slate-900'
                : 'cursor-not-allowed bg-slate-200 text-slate-900'
            }`}
            type="submit"
            disabled={!isValid || !isDirty}
            onClick={onSubmit}
            data-tip={
              !isValid && isDirty ? 'Please fix any errors in the form' : ''
            }
          >
            Create Account
          </button>
        </form>
      </div>
      <ToolTip />
    </>
  )
}

export default Register
