import { Auth } from 'aws-amplify'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { setIsAuthenticating } from '../../features/auth/authSlice'
import Spinner from '../../components/Spinner'
import { toast } from 'react-toastify'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'

const Login = () => {
  const dispatch = useAppDispatch()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleLogin = (e: any) => {
    e.preventDefault()

    const tryLogin = async () => {
      dispatch(setIsAuthenticating(true))
      const status = await Auth.signIn(username, password)

      dispatch(setIsAuthenticating(false))
      return status
    }

    toast.promise(
      tryLogin,
      {
        pending: {
          render: () => 'Logging in...',
          type: 'info',
          icon: <Spinner />,
        },
        success: {
          render: () => 'Login Successful',
          type: 'success',
          icon: <AiOutlineCheckCircle className="text-tik-500" />,
        },
        // @ts-ignore
        error: {
          render: (error: any) => `Login Failed: ${error.data}`,
          type: 'error',
          icon: <AiOutlineCloseCircle className="text-red-500" />,
        },
      },
      {
        position: 'bottom-center',
      }
    )
  }

  return (
    <>
      <div className="mx-auto my-auto w-96 rounded-md border p-2 text-center">
        <p>Login</p>
        <form className="flex flex-col gap-2">
          <div className="flex flex-col">
            <label htmlFor="user">Username</label>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              name="user"
              type="username"
              className="rounded-md p-2 dark:text-slate-900"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="pass">Password</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="pass"
              type="password"
              className="rounded-md p-2 dark:text-slate-900"
            />
          </div>
          <div>
            <button
              className="rounded-md border px-6 py-4 shadow-md"
              type="submit"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </form>
        <div>
          <span>Don't have an account?</span>{' '}
          <Link to="/register" className="text-tik-500">
            Register Here
          </Link>
        </div>
      </div>
    </>
  )
}

export default Login
