import { FaSpinner } from "react-icons/fa"

const Spinner = () => {
  return (
    <div className="text-tik-400 m-auto text-xl py-2">
      <FaSpinner className="animate-spin" />
    </div>
  )
}

export default Spinner