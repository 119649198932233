import { Auth } from 'aws-amplify'
import { DayType, ServerDayType } from '../../model/Day'
import toDateStamp from '../../utils/dates/toDateStamp'

const url = `https://4ah6p2plk7.execute-api.us-east-1.amazonaws.com/dev/timesheets`
const method = 'POST'

export const post = async (body: any) => {
  const token = await Auth.currentSession()
  const idToken = token.getIdToken().getJwtToken()

  const headers = {
    Authorization: idToken,
    'Content-Type': 'application/json',
  }

  if (body?.days) {
    let daysForServer: ServerDayType = body.days.map((day: DayType) => {
      return {
        ...day,
        date: toDateStamp(new Date(day.date)),
      }
    })
    body.days = daysForServer
  }

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  })

  return response.json()
}

export const postToZoho = async (body: any) => {
  const token = await Auth.currentSession()
  const idToken = token.getIdToken().getJwtToken()

  const headers = {
    Authorization: idToken,
    'Content-Type': 'application/json',
  }

  const response = await fetch(url + '/zoho', {
    method,
    headers,
    body: JSON.stringify(body),
  })

  return response.json()
}
