/*
  Approveremail: string
  Approvername: string
  Contractid: string
  Firstdate: string
  Notes: string
  Periodcount: number // Number of weeks
  Periods: string
  Submitteremail: string
  Submittername: string
  Totaldays: number
  Totalhours: number
*/

import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { postToZoho } from '../../../api/timesheets/post'
import { ZohoWebhookType } from '../../../api/zoho/post'

const useSendTimesheetToZoho = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const SubmitWebhookRequest = async (body: ZohoWebhookType) => {
    try {
      await postToZoho(body)
    } catch (error) {
      console.log(error)
    }
  }

  const FetchUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()

      // Get the name from the users attributes
      const name = user?.attributes?.name
      const email = user?.attributes?.email

      setName(name)
      setEmail(email)

      return { name, email }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    FetchUser()
  }, [])

  const sendTimesheetToZoho = async (timesheet: {
    firstDate: string
    periods: any[]
    totalDays: number
    totalHours: number
  }) => {
    let timesheetName = name
    let timesheetEmail = email
    if (!name || !email) {
      const userAttributes = await FetchUser()

      if (userAttributes?.name && userAttributes?.email) {
        timesheetName = userAttributes.name
        timesheetEmail = userAttributes.email
      } else {
        return
      }
    }

    // periods = array of 7 days per period

    const zohoWebhook: ZohoWebhookType = {
      approverEmail: 'su.ng@mgisinc.ca',
      approverName: 'Su Ng',
      contractID: '123',
      firstDate: timesheet.firstDate,
      notes: '',
      periodCount: timesheet.periods.length,
      periods: timesheet.periods,
      submitterEmail: timesheetEmail,
      submitterName: timesheetName,
      totalDays: timesheet.totalDays,
      totalHours: timesheet.totalHours,
    }

    return await SubmitWebhookRequest(zohoWebhook)
  }

  return sendTimesheetToZoho
}

export default useSendTimesheetToZoho
