import nextFriday from 'date-fns/nextFriday'
import nextMonday from 'date-fns/nextMonday'
import nextSaturday from 'date-fns/nextSaturday'
import nextSunday from 'date-fns/nextSunday'
import nextThursday from 'date-fns/nextThursday'
import nextTuesday from 'date-fns/nextTuesday'
import nextWednesday from 'date-fns/nextWednesday'
import { useEffect, useState } from 'react'

const useWeekDays = () => {
  const [weekDates, setWeekDates] = useState<Date[]>([])

  useEffect(() => {
    let monday = nextMonday(new Date(2021, 1, 4)) // Randomn monday
    let tuesday = nextTuesday(monday)
    let wednesday = nextWednesday(monday)
    let thursday = nextThursday(monday)
    let friday = nextFriday(monday)
    let saturday = nextSaturday(monday)
    let sunday = nextSunday(monday)

    setWeekDates([
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    ])
  }, [])

  return weekDates
}

export default useWeekDays
