import { ZohoWebhookPeriodDateType } from '../../../api/zoho/post'
import { DayType } from '../../../model/Day'

const convertToZohoDay = (day: DayType): ZohoWebhookPeriodDateType => {
  let title = day?.hours
    ? day.hours + ''
    : day?.isSick
    ? 'Sick'
    : day?.isVacation
    ? 'Vacation'
    : '-'

  return {
    title,
    date: new Date(day.date).toISOString(),
    valid: true,
  }
}

export default convertToZohoDay
