import { eachDayOfInterval, endOfWeek, startOfWeek, isWeekend } from 'date-fns'
import { debounce, throttle } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import Spinner from '../../components/Spinner'
import { DayType } from '../../model/Day'
import getWeeksInMonth from '../../utils/dates/getWeeksInMonth'
import useSendTimesheetToZoho from '../timesheet/hooks/useSendTimesheetToZoho'
import { SubmitTimesheet, TimesheetType } from '../timesheet/timesheetSlice'
import {
  daySelectors,
  SaveSelectedDays,
  selectCalendarDate,
  selectDate,
  selectSelectedDate,
  setDate,
} from './calendarSlice'
import Nav from './components/Nav'
import WeekDays from './components/WeekDays'
import BuildTimesheet from './utils/BuildTimesheet'
import BuildZohoTimesheet from './utils/BuildZohoTimesheet'

const NewCalendarHeaders = () => {
  return (
    <div className="grid grid-cols-8 border-t border-b bg-slate-100 py-3 text-center text-xs dark:bg-slate-800 md:text-sm lg:text-base">
      <div>
        <h2>Starting</h2>
      </div>
      <div className="col-span-7">
        <WeekDays />
      </div>
    </div>
  )
}

const CalendarItemBox = ({
  date,
  text,
  notWorkingDay,
}: {
  date: Date
  text?: string
  notWorkingDay?: boolean
}) => {
  const dispatch = useAppDispatch()
  const selectedDates = useAppSelector(selectSelectedDate)
  const { hours, isSick, isVacation, updatedAt } = {
    ...useAppSelector((state) =>
      daySelectors.selectAll(state).find((day) => +day.date === +date)
    ),
  }

  // const [isOpen, setIsOpen] = useState<boolean>(false)
  const isSelected = selectedDates.includes(+date)
  const [isTextBoxVisible, setIsTextBoxVisible] = useState<boolean>(false)
  const [value, setValue] = useState<string | number>('')
  const [isPendingUpdate, setIsPendingUpdate] = useState<boolean>(false)
  const [shouldClose, setShouldClose] = useState<boolean>(false)
  const weekend = isWeekend(date)

  useEffect(() => {
    setIsPendingUpdate(false)
    // When the date info is updated, set pending to be false
  }, [hours, isSick, isVacation, updatedAt])

  useEffect(() => {
    // If it's still open but should close, close it when there's no pending update
    if (isSelected && !isPendingUpdate && shouldClose) {
      dispatch(selectDate(+date))
      setShouldClose(false)
    }
  }, [isPendingUpdate, isSelected, shouldClose])

  const UpdateDay = useCallback(
    debounce((val) => {
      // console.log('UpdateDay debounce')
      // console.log(val)
      if (!isNaN(+val)) {
        val = +val
      }
      // does nothing if the user clicked out because the date isnt selected anymore
      dispatch(SaveSelectedDays(val))
    }, 1000),
    []
  )

  const handleClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setIsTextBoxVisible(true)
    dispatch(selectDate(+date))
  }

  const handleTextChange = (e: any) => {
    e.preventDefault()

    let input = e.target.value

    setValue(input)

    if (input !== null && input !== undefined) {
      setIsPendingUpdate(true)
      UpdateDay(input)
    }
  }

  const handleFocusLost = (e: any) => {
    e.preventDefault()
    setIsTextBoxVisible(false)
    if (!isPendingUpdate) {
      dispatch(selectDate(+date))
    } else {
      setShouldClose(true)
    }
  }

  return (
    <>
      {isTextBoxVisible ? (
        <div>
          <input
            onClick={(e) => e.stopPropagation()}
            autoFocus={true}
            onBlur={handleFocusLost}
            type="text"
            className="h-10 w-20 border-2 border-gray-400 p-2 text-slate-900 dark:border-gray-800"
            value={value}
            onChange={handleTextChange}
          />
        </div>
      ) : (
        <div
          onClick={handleClick}
          className={`grid h-10 w-12 cursor-pointer place-content-center place-items-center rounded-md border text-center align-middle shadow-sm shadow-slate-900 ${
            notWorkingDay ? 'bg-slate-300/25 dark:bg-slate-300/25' : ''
          }
      ${weekend ? 'bg-slate-300/25 dark:bg-slate-300/25' : ''}`}
        >
          {/* Get the hours from the dayInfo if it exists, if not display if its a sick day or a vacation day */}
          {isPendingUpdate ? (
            <Spinner />
          ) : hours && !isNaN(+hours) ? (
            <span>{hours}</span>
          ) : isSick ? (
            <span>Sick</span>
          ) : isVacation ? (
            <span>Vacation</span>
          ) : (
            <span className="">-</span>
          )}
        </div>
      )}
    </>
  )
}

const NewCalendar = () => {
  const calendarDate = useAppSelector(selectCalendarDate)
  const dispatch = useAppDispatch()
  const submitToZoho = useSendTimesheetToZoho()
  const selectedDates = useAppSelector(selectSelectedDate)
  const days = useAppSelector((state) => daySelectors.selectAll(state))
  const [shouldSubmitToZoho, setShouldSubmitToZoho] = useState(true)

  const [weeks, setWeeks] = useState<Date[]>([])
  const [selectedWeeks, setSelectedWeeks] = useState<Date[]>([])

  useEffect(() => {
    // Create an array of weeks in this month
    const weeks = getWeeksInMonth(calendarDate)
    setWeeks(weeks)
  }, [calendarDate])

  const handleSelect = (date: Date) => {
    let selected = [...selectedWeeks]
    if (selected.includes(date)) {
      selected = selected.filter((item: Date) => item !== date)
    } else {
      selected.push(date)
    }
    setSelectedWeeks(selected)
  }

  const NavigateToCurrentDate = () => {
    dispatch(setDate(+new Date()))
  }

  const SubmitTimesheetToZoho = (timesheets: TimesheetType[]) => {
    let zohoDetails = BuildZohoTimesheet(timesheets)
    toast.promise(
      submitToZoho(zohoDetails),
      {
        pending: 'Submitting to Zoho...',
        success: 'Timesheet submitted to Zoho',
        error: 'Error submitting to Zoho',
      },
      {
        position: 'bottom-center',
      }
    )
  }

  const SubmitTimesheetToBackend = (timesheet: TimesheetType) => {
    let duration: number = timesheet?.days?.length || 0
    dispatch(SubmitTimesheet({ start: timesheet.start, duration }))

    toast(
      `Timesheet submitted for ${new Date(
        timesheet.start
      ).toLocaleDateString()}`,
      {
        position: 'bottom-center',
        type: 'success',
      }
    )

    // Reset the selected timesheets
    setSelectedWeeks([] as Date[])
  }

  const handleSubmitClick = () => {
    let timesheets: TimesheetType[] = [] as TimesheetType[]

    // Build the timesheets
    selectedWeeks.forEach((item: Date) => {
      timesheets.push(BuildTimesheet(item, days))
    })

    // Submit to backend
    timesheets.forEach((item: TimesheetType) => {
      SubmitTimesheetToBackend(item)
    })

    // Submit to Zoho
    if (shouldSubmitToZoho) {
      SubmitTimesheetToZoho(timesheets)
    }

    flushSync(() => {
      // Reset the selected timesheets
      setSelectedWeeks([])
    })
  }

  return (
    <div>
      <div className="flex flex-row bg-slate-100 py-6 pb-9 dark:bg-slate-800">
        <div className="grid w-3/5 grid-cols-5 grid-rows-1 place-items-center justify-center">
          <Nav />
          <button
            onClick={NavigateToCurrentDate}
            className="mx-4 rounded-md border px-4 py-2"
          >
            Today
          </button>
        </div>
        <button
          onClick={handleSubmitClick}
          className="my-1 mx-2 ml-auto rounded-md border border-tik-100 bg-tik-400 py-2 px-4 text-slate-900"
        >
          Submit
        </button>
      </div>
      <div>
        <NewCalendarHeaders />
      </div>
      <div className="grid grid-cols-8">
        {weeks.map((week) => {
          const isSelected = selectedWeeks.includes(week)
          const dates = eachDayOfInterval({
            start: startOfWeek(week, { weekStartsOn: 1 }),
            end: endOfWeek(week, { weekStartsOn: 1 }),
          })
          return (
            <div
              key={week.toString()}
              className={`col-span-8 grid h-20 grid-cols-8 place-items-center border-b text-xs md:text-sm lg:text-base ${
                isSelected
                  ? 'odd:bg-tik-400/50 even:bg-tik-500/50'
                  : 'even:bg-slate-100 dark:odd:bg-slate-700 dark:even:bg-slate-800'
              }`}
              onClick={() => handleSelect(week)}
            >
              <p>
                {week.toLocaleDateString(undefined, {
                  day: '2-digit',
                  weekday: undefined,
                  month: 'short',
                })}
              </p>
              <CalendarItemBox date={dates[0]} text={'-'} />
              <CalendarItemBox date={dates[1]} text={'-'} />
              <CalendarItemBox date={dates[2]} text={'-'} />
              <CalendarItemBox date={dates[3]} text={'-'} />
              <CalendarItemBox date={dates[4]} text={'-'} />
              <CalendarItemBox date={dates[5]} text={'-'} />
              <CalendarItemBox date={dates[6]} text={'-'} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NewCalendar
