import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfWeek from 'date-fns/endOfWeek'
import { DayType } from '../../../model/Day'
import { TimesheetType } from '../../timesheet/timesheetSlice'
import Monday from '../constants/Monday'
import GetDayDetails from './GetDayDetails'

const BuildTimesheet = (start: Date, allDays: DayType[]): TimesheetType => {
  let timesheet = { id: 'test' } as TimesheetType
  const end = endOfWeek(start, { weekStartsOn: Monday })
  const days = eachDayOfInterval({ start, end })

  // Get the days stored in the state for the interval
  const daysInState: DayType[] = days.map((day) => GetDayDetails(allDays, day))

  timesheet.start = +start
  timesheet.end = +end
  timesheet.days = daysInState

  return timesheet
}

export default BuildTimesheet
