import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import Calendar from '../features/calendar/Calendar'
import {
  daySelectors,
  DeleteSelectedDays,
  FetchAllDays,
  SaveSelectedDays,
  selectSelectedDate,
} from '../features/calendar/calendarSlice'
import Nav from '../features/calendar/components/Nav'
import NewCalendar from '../features/calendar/NewCalendar'
import useTimedDisable from '../hooks/useTimedDisable'
import { DayType } from '../model/Day'

const Home = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    // const test = async () => {
    //   const user = await Auth.currentAuthenticatedUser()
    //   console.log(user)
    // }

    // test()
    dispatch(FetchAllDays())
  }, [])
  return (
    <>
      <NewCalendar />
    </>
  )
}

const _Home = () => {
  const dispatch = useAppDispatch()
  const selectedDates = useAppSelector(selectSelectedDate)
  const dates = useAppSelector(daySelectors.selectAll)
  const [value, setValue] = useState<string>('')
  const [isSaveDisabled, disableSave] = useTimedDisable(500)
  const [isDeleteDisabled, disableDelete] = useTimedDisable(500)
  const [isSickDisabled, disableSick] = useTimedDisable(500)
  const [isVacationDisabled, disableVacation] = useTimedDisable(500)
  const [isFullDayDisabled, disableFullDay] = useTimedDisable(500)
  const [dateValue, setDateValue] = useState<string>('')

  const GetValueOfDate = (date: DayType) => {
    if (date.hours !== null && date.hours !== undefined) {
      return date.hours
    } else if (
      date.isSick !== null &&
      date.isSick !== undefined &&
      date.isSick
    ) {
      return 'Sick'
    } else if (
      date.isVacation !== null &&
      date.isVacation !== undefined &&
      date.isVacation
    ) {
      return 'Vacation'
    }
  }

  useEffect(() => {
    if (dates?.length > 0 && selectedDates?.length > 0) {
      const validDates = dates.filter((day) => selectedDates.includes(day.date))

      if (validDates.length === 0) {
        setDateValue('')
      } else {
        let value = validDates.map(GetValueOfDate)

        // @ts-ignore
        value = [...new Set([...value])]

        if (value !== null && value !== undefined && value.length === 1) {
          setDateValue(value.toString())
        } else {
          setDateValue('')
        }
      }
    }
  }, [selectedDates, dates])

  const handleSaveDates = (e: any) => {
    e.preventDefault()
    if (selectedDates?.length > 0) {
      disableSave()
      dispatch(SaveSelectedDays(value))

      toast('Saving the selected Days', {
        type: 'info',
        position: 'bottom-center',
      })
    }
  }
  const handleDeleteDates = (e: any) => {
    e.preventDefault()
    if (selectedDates?.length > 0) {
      disableDelete()
      dispatch(DeleteSelectedDays())

      toast('Deleting the selected Days', {
        type: 'info',
        position: 'bottom-center',
      })
    }
  }

  const handleSickClick = (e: any) => {
    e.preventDefault()

    if (selectedDates?.length > 0) {
      disableSick()
      if (dateValue.toLowerCase() === 'sick') {
        dispatch(SaveSelectedDays(''))
      } else {
        dispatch(SaveSelectedDays('Sick'))
      }

      toast('Saving the selected Days', {
        type: 'info',
        position: 'bottom-center',
      })
    }
  }

  const handleVacationClick = (e: any) => {
    e.preventDefault()

    if (selectedDates?.length > 0) {
      disableVacation()
      if (dateValue.toLowerCase() === 'vacation') {
        dispatch(SaveSelectedDays(''))
      } else {
        dispatch(SaveSelectedDays('Vacation'))
      }

      toast('Saving the selected Days', {
        type: 'info',
        position: 'bottom-center',
      })
    }
  }

  const handleFullDayClick = (e: any) => {
    e.preventDefault()

    if (selectedDates?.length > 0) {
      disableFullDay()
      if (dateValue && !isNaN(+dateValue) && +dateValue === 7.5) {
        dispatch(SaveSelectedDays('0'))
      } else {
        dispatch(SaveSelectedDays('7.5'))
      }

      toast('Saving the selected Days', {
        type: 'info',
        position: 'bottom-center',
      })
    }
  }

  return (
    <>
      <Calendar />
      {selectedDates && selectedDates.length > 0 ? (
        <>
          <form className="m-auto flex max-w-screen-sm flex-col gap-4 rounded-md border bg-slate-50 p-4 shadow-sm dark:bg-slate-800">
            <h1 className="text-center">Edit Selected Dates</h1>
            <div className="flex justify-around">
              <button
                className={`h-[8ch] w-[8ch] rounded-[100%] border ${
                  dateValue === 'Sick'
                    ? 'bg-tik-400 text-slate-900 shadow-md'
                    : 'bg-slate-200 shadow-sm dark:bg-slate-900'
                }`}
                disabled={isSickDisabled}
                onClick={handleSickClick}
              >
                Sick
              </button>
              <button
                className={`h-[8ch] w-[8ch] rounded-[100%] border ${
                  dateValue === 'Vacation'
                    ? 'bg-tik-400 text-slate-900 shadow-md'
                    : 'bg-slate-200 shadow-sm dark:bg-slate-900'
                }`}
                disabled={isVacationDisabled}
                onClick={handleVacationClick}
              >
                Vacation
              </button>
              <button
                className={`h-[8ch] w-[8ch] rounded-[100%] border ${
                  !isNaN(+dateValue) && +dateValue === 7.5
                    ? 'bg-tik-400 text-slate-900 shadow-md'
                    : 'bg-slate-200 shadow-sm dark:bg-slate-900'
                }`}
                disabled={isFullDayDisabled}
                onClick={handleFullDayClick}
              >
                Full Day
              </button>
            </div>
            <div className="flex flex-col gap-4">
              <label htmlFor="date">Manual Time Input</label>
              <input
                className="rounded-md border px-2 accent-tik-500 dark:text-slate-900"
                name="date"
                type="text"
                value={value}
                placeholder="EG: 5, 6, 7.5"
                onChange={(e) => setValue(e.target.value)}
              ></input>
            </div>
            <div className="flex flex-row justify-around">
              <button
                onClick={handleSaveDates}
                disabled={isSaveDisabled}
                className="m-auto w-fit rounded-md border bg-blue-400 px-6 py-4 text-slate-900  shadow-sm hover:bg-blue-500 active:bg-blue-600"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={handleDeleteDates}
                disabled={isDeleteDisabled}
                className="m-auto w-fit rounded-md border bg-red-400 px-6 py-4 text-slate-900  shadow-sm hover:bg-red-500 active:bg-red-600"
              >
                Delete
              </button>
            </div>
          </form>
        </>
      ) : null}
      <Link
        to="/submit"
        className="mx-auto mt-2 grid max-w-fit grid-flow-col place-content-center place-items-center gap-2 rounded-md bg-tik-400 px-4 py-2 font-semibold text-slate-900 shadow-sm"
      >
        <span>Continue to Submit</span>
        <AiOutlineSend />
      </Link>
    </>
  )
}

export default Home
