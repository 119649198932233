import {
  eachDayOfInterval,
  endOfISOWeek,
  endOfMonth,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  FetchAllDays,
  isCalendarLoading,
  selectCalendarDate,
  selectDate,
  // selectDays,
  selectSelectedDate,
} from './calendarSlice'
import CalendarItem from './components/CalendarItem'
import Nav from './components/Nav'
import WeekDays from './components/WeekDays'

const Calendar = () => {
  const dispatch = useAppDispatch()
  const calendarDate = useAppSelector(selectCalendarDate)
  const selectedDate = useAppSelector(selectSelectedDate)
  // const days = useAppSelector(selectDays)
  const isLoading = useAppSelector(isCalendarLoading)
  const [dates, setDates] = useState<Date[]>([])

  useEffect(() => {
    dispatch(FetchAllDays())
  }, [dispatch])

  useEffect(() => {
    const start = startOfDay(startOfISOWeek(startOfMonth(calendarDate)))
    const end = startOfDay(endOfISOWeek(endOfMonth(calendarDate)))

    setDates(eachDayOfInterval({ start, end }))
  }, [calendarDate])

  const handleClickDate = (e: any) => {
    e.preventDefault()
    if (e?.target?.dataset?.date) {
      dispatch(selectDate(+e.target.dataset.date))
    }
  }

  return (
    <div className="m-2 mx-auto grid max-w-screen-sm select-none grid-cols-7 grid-rows-[0.4fr_0.4fr] rounded-md border bg-slate-50 p-2 text-center shadow-sm dark:bg-slate-800 dark:text-slate-50">
      <div className="col-span-7 grid grid-cols-6">
        <Nav multi />
      </div>
      <div className="col-span-7">
        <WeekDays />
      </div>
      <div className="col-span-7 grid grid-cols-7 grid-rows-6 gap-2">
        {dates.map((item: Date, idx: number) => {
          const isSelected = !!selectedDate.find(
            (selectedItem) => selectedItem === +item
          )

          return (
            <CalendarItem
              key={`calendarItem-${idx.toString()}`}
              date={item}
              isSelected={isSelected}
              onClick={handleClickDate}
              isLoading={isLoading}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Calendar
