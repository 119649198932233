import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  nanoid,
  PayloadAction,
} from '@reduxjs/toolkit'
import { addDays, eachDayOfInterval } from 'date-fns'
import { getAll } from '../../api/timesheets/get'
import { post } from '../../api/timesheets/post'
import { RootState } from '../../app/store'
import { DayType } from '../../model/Day'
import { daySelectors } from '../calendar/calendarSlice'

type Unapproved = 'in_approval_process'
type Completed = 'completed'

export type TimesheetType = {
  id: string
  userId?: string
  sortKey?: string
  createdAt?: number
  updatedAt?: number

  start: number
  end?: number
  days?: DayType[]
  state?: Unapproved | Completed

  documentId?: string
}

const TimesheetAdapter = createEntityAdapter({
  selectId: (timesheet: TimesheetType) => timesheet.id,
  sortComparer: (a: TimesheetType, b: TimesheetType) => {
    return a.start > b.start ? 1 : -1
  },
})

export interface TimesheetState {
  isLoading: boolean
  selectedTimesheets: any[]
}

const initialState: TimesheetState = {
  isLoading: false,
  selectedTimesheets: [],
}

export const FetchAllTimesheets = createAsyncThunk('fetchAllDays', async () => {
  const response = await getAll()

  return response
})

export const SubmitTimesheet = createAsyncThunk(
  'submitTimesheet',
  async (
    { start, duration }: { start: number; duration: number },
    { getState }
  ): // @ts-ignore
  Promise<TimesheetType> => {
    const selectedDates: DayType[] = daySelectors.selectAll(
      getState() as RootState
    )

    // Dates in timestamps
    const end = addDays(start, duration - 1)
    const interval: number[] = eachDayOfInterval({
      start,
      end,
    }).map((i) => +i)

    // console.log(`selectedDates within submit`, selectedDates)
    // const daysInInterval = selectedDates.filter((day) =>
    //   interval.includes(+day.date)
    // )

    // Find each day in the selected dates
    // If it is not in the selected dates, create a new day
    let daysInTimesheet: DayType[] = interval.map((date: number): DayType => {
      let day: DayType | undefined = selectedDates.find((d) => +d.date === date)

      if (day) {
        return day
      } else {
        let newDay: DayType = {
          id: nanoid(),
          date,
          createdAt: new Date().getTime(),
          updatedAt: new Date().getTime(),
        }
        return newDay
      }
    })

    const timesheet: TimesheetType = {
      id: nanoid(),
      start: +start,
      end: +end,
      days: daysInTimesheet,
    }

    return await post(timesheet)
    // If days dont match, need to create new empty days to fill in the gaps

    // const response = await fetch(`/api/timesheets/${idk}`, {})

    // @ts-ignore
    // return response
  }
)

export const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState: TimesheetAdapter.getInitialState(initialState),
  reducers: {
    addTimesheet: (
      state,
      action: PayloadAction<Partial<TimesheetType> & any[]>
    ) => {},
    loadTimesheets: (state) => {
      // let tempTimesheets = [] as TimesheetType[]
      // for (let i = 0; i < 5; i++) {
      //   let id = i.toString()
      //   let owner = '123'
      //   let sortKey = '2020-01-01'
      //   let start = +new Date()
      //   let duration = 7
      //   let isComplete = false
      //   tempTimesheets.push({
      //     id,
      //     owner,
      //     sortKey,
      //     start,
      //     duration,
      //     isComplete,
      //   })
      // }
      // state.timesheets = tempTimesheets
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SubmitTimesheet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(SubmitTimesheet.fulfilled, (state, action) => {
        state.isLoading = false
        TimesheetAdapter.addOne(state, action.payload)
      })
      .addCase(SubmitTimesheet.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(FetchAllTimesheets.pending, (state) => {
        state.isLoading = true
      })
      .addCase(FetchAllTimesheets.fulfilled, (state, action) => {
        state.isLoading = false
        TimesheetAdapter.addMany(state, action.payload)
      })
      .addCase(FetchAllTimesheets.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export const { loadTimesheets, addTimesheet } = timesheetSlice.actions

// export const selectTimesheets = (state: RootState) => state.timesheet.timesheets
export const timesheetSelectors = TimesheetAdapter.getSelectors(
  (state: RootState) => state.timesheet
)

export default timesheetSlice.reducer
