import { ImSpinner2 } from 'react-icons/im'

const BadgeLoading = () => {
  return (
    <div className="pointer-events-none grid h-4 w-4 place-items-center">
      <ImSpinner2 className="h-3 w-3 animate-spin place-self-center" />
    </div>
  )
}
export default BadgeLoading
