import './App.css'
import Navigation from './components/navigation/Navigation'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/auth/Login'
import Logout from './pages/auth/Logout'
import Register from './pages/auth/Register'
import useAuth from './features/auth/hooks/useAuth'
import Users from './pages/admin/Users'
import AdminHome from './pages/admin/Home'
import Signature from './pages/user/Signature'
import Profile from './pages/user/Profile'
import UserHome from './pages/user/Home'
import History from './pages/History'
import Contracts from './pages/admin/Contracts'
import Submit from './pages/days/Submit'
import Settings from './pages/settings/Home'
import { ToastContainer } from 'react-toastify'
import useDoesPreferDarkMode from './hooks/useDoesPreferDarkMode'
import Delete from './pages/auth/Delete'
import useScreenSize from './hooks/useScreenSize'
import Calendar from './pages/calendar/Home'

function App() {
  useAuth()
  const prefersDark = useDoesPreferDarkMode()
  const screenSize = useScreenSize()

  return (
    <div className="select flex h-screen max-h-screen w-screen flex-col-reverse justify-end selection:bg-tik-300 selection:text-black md:flex-row">
      <Navigation />
      <div className="mb-20 h-screen max-h-screen w-full overflow-y-auto md:mb-auto md:h-auto">
        <Routes>
          {/* Public */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/login" element={<Login />} />
          {/* Require login */}
          <Route path="/submit" element={<Submit />} />
          <Route path="/history" element={<History />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/auth/delete" element={<Delete />} />
          <Route path="/user" element={<UserHome />} />
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/user/signature" element={<Signature />} />
          <Route path="/settings" element={<Settings />} />
          {/* Admin */}
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/contracts" element={<Contracts />} />
        </Routes>
      </div>
      <ToastContainer
        className="absolute"
        theme={prefersDark ? 'dark' : 'light'}
      />
    </div>
  )
}

export default App
