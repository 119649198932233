import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import Avatar from '../../components/profile/Avatar'
import Spinner from '../../components/Spinner'
import useRequireAuthentication from '../../features/auth/hooks/useRequireAuthentication'

const Profile = () => {
  useRequireAuthentication()
  const [name, setName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    let isMounted = true
    const UpdateInfo = async () => {
      const user = await Auth.currentAuthenticatedUser()

      if (isMounted) {
        if (user?.attributes?.name) {
          setName(user.attributes.name)
        }
        if (user?.attributes?.phone_number) {
          setPhone(user.attributes.phone_number)
        }
        if (user?.attributes?.email) {
          setEmail(user.attributes.email)
        }
      }
    }

    UpdateInfo()

    return () => {
      isMounted = false
    }
  }, [])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    let attributes = {
      name,
      phone_number: phone,
    }

    if (phone) {
      // Check if the phone begins with a +
      if (!phone.startsWith('+')) {
        attributes.phone_number = `+${phone}`
      }
    }

    const UpdateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        await Auth.updateUserAttributes(user, attributes)
        const profileAttributes = await Auth.userAttributes(user)

        const profileNumber = profileAttributes.find(
          (item) => item?.Name === 'phone_number'
        )?.Value
        const profileEmail = profileAttributes.find(
          (item) => item?.Name === 'email'
        )?.Value
        const profileName = profileAttributes.find(
          (item) => item?.Name === 'name'
        )?.Value

        if (profileNumber) {
          setPhone(profileNumber)
        }
        if (profileEmail) {
          setEmail(profileEmail)
        }
        if (profileName) {
          setName(profileName)
        }
      } catch (err: any) {
        throw new Error(err?.message)
      }
    }

    toast.promise(UpdateUser, {
      pending: {
        render: () => 'Updating...',
        type: 'info',
        icon: <Spinner />,
      },
      success: {
        render: () => 'Update Successful',
        type: 'success',
        icon: <AiOutlineCheckCircle className="text-tik-500" />,
      },
      error: {
        render: (error: any) => `Update Failed: ${error.data}`,
        type: 'error',
        icon: <AiOutlineCloseCircle className="text-red-500" />,
      },
    })
  }

  const handleNameChange = (e: any) => {
    e.preventDefault()

    setName(e.target.value)
  }

  const handlePhoneChange = (e: any) => {
    e.preventDefault()

    setPhone(e.target.value)
  }

  return (
    <div className="m-auto flex flex-col gap-8">
      <div>
        <Avatar />
      </div>
      <form className="mx-auto flex max-w-lg flex-col gap-4 rounded-md border p-4 shadow-sm">
        <div className="flex flex-col gap-2">
          <label htmlFor="email">Email</label>
          <input
            value={email}
            name="email"
            disabled
            type="email"
            className="rounded-md border border-tik-400 p-2 dark:text-slate-50"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="user">Full Name</label>
          <input
            value={name}
            onChange={handleNameChange}
            name="user"
            type="username"
            className="rounded-md border border-tik-400 p-2 dark:text-slate-900"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="phone">Phone</label>
          <input
            value={phone}
            onChange={handlePhoneChange}
            name="phone"
            type="phone"
            className="rounded-md border border-tik-400 p-2 dark:text-slate-900"
          />
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={handleSubmit}
            className="rounded-md bg-tik-400 p-2 dark:text-slate-900"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  )
}

export default Profile
