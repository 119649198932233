import { useLayoutEffect, useState } from 'react'
import debounce from 'lodash/debounce'

type ScreenSizes = 'mobile' | 'tablet' | 'desktop'
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSizes>('desktop')

  useLayoutEffect(() => {
    let isMounted = true

    const handleResize = () => {
      if (isMounted) {
        const width = window.innerWidth

        if (width < 768) {
          setScreenSize('mobile')
        } else if (width < 1024) {
          setScreenSize('tablet')
        } else {
          setScreenSize('desktop')
        }
      }
    }

    window.addEventListener('resize', debounce(handleResize, 50))

    return () => {
      isMounted = false
    }
  }, [])

  return screenSize
}

export default useScreenSize
