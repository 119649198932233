import eachWeekOfInterval from 'date-fns/eachWeekOfInterval'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'

const getWeeksInMonth = (
  selectedMonth: Date | number,
  startsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
): Date[] => {
  let date = new Date(selectedMonth)
  const start = startOfMonth(date)
  const end = endOfMonth(date)
  const weekDayStart = startsOn || 1 // Defaults to Monday
  const weekList = eachWeekOfInterval(
    { start, end },
    { weekStartsOn: weekDayStart }
  )

  return weekList
}

export default getWeeksInMonth
