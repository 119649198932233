import { useAppSelector } from '../../../app/hooks'
import { daySelectors } from '../calendarSlice'
import { BadgeLoading } from './badges'

const CalendarItem = ({
  date,
  isSelected,
  onClick,
  isLoading,
}: {
  date: Date
  isSelected: boolean
  onClick: (e: any) => void
  isLoading: boolean
}) => {
  const dayInfo = useAppSelector((state) =>
    daySelectors.selectAll(state).find((day) => +day.date === +date)
  )
  // const dayInfo = useAppSelector((state) =>
  //   daySelectors.selectById(state, +date)
  // )
  const isSick = dayInfo?.isSick
  const isVacation = dayInfo?.isVacation
  const hours = dayInfo?.hours || 0

  return (
    <div
      data-date={+date}
      onClick={onClick}
      className={`cursor-pointer rounded-md border p-2 ${
        isSelected ? 'bg-tik-100 dark:bg-tik-800' : ''
      }`}
    >
      <span className="pointer-events-none">{date.getDate().toString()}</span>
      <div className="pointer-events-none m-auto grid h-5 w-5 place-items-center justify-center align-middle">
        {isLoading ? (
          <BadgeLoading />
        ) : isSick ? (
          <span>Sick</span>
        ) : isVacation ? (
          <span>Vacation</span>
        ) : hours > 0 ? (
          <span>{hours.toString()}</span>
        ) : null}
      </div>
    </div>
  )
}

export default CalendarItem
