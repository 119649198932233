import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export interface AuthState {
  isAdmin: boolean
  isAuthenticated: boolean
  isAuthenticating: boolean
}

const initialState: AuthState = {
  isAdmin: false,
  isAuthenticated: false,
  isAuthenticating: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload
    },
    setIsAuthenticating: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticating = action.payload
    },
  },
})

export const { setIsAuthenticated, setIsAdmin, setIsAuthenticating } =
  authSlice.actions

export const selectIsAdmin = (state: RootState) => state.auth.isAdmin
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated
export const selectIsAuthenticating = (state: RootState) =>
  state.auth.isAuthenticating

export default authSlice.reducer
