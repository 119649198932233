import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import useWeekDays from '../../hooks/dates/useWeekDays'
import { DayType } from '../../model/Day'
import { loadTimesheets, timesheetSelectors } from './timesheetSlice'

const TimesheetHeaders = () => {
  const weekDays = useWeekDays()
  return (
    <div className="grid grid-cols-8 bg-slate-100 py-4 text-center dark:bg-slate-800">
      <div>
        <h2>Week Starting</h2>
      </div>
      {weekDays.map((item: Date, idx: number) => {
        return (
          <abbr
            key={`weekday-${idx.toString()}`}
            title={item.toLocaleDateString(undefined, { weekday: 'long' })}
          >
            {item.toLocaleDateString(undefined, { weekday: 'short' })}
          </abbr>
        )
      })}
    </div>
  )
}

const TimesheetRow = ({
  days,
  start,
  onClick,
}: {
  days: DayType[]
  start: number
  onClick: () => void
}) => {
  return (
    <div
      onClick={onClick}
      className="grid grid-cols-8 text-center even:bg-slate-100 dark:even:bg-slate-800"
    >
      {start && (
        <p>
          {new Date(start).toLocaleDateString(undefined, {
            day: '2-digit',
            weekday: undefined,
            month: 'short',
          })}
        </p>
      )}

      {days.map((item: DayType, idx: number) => {
        return (
          // <p>ok</p>
          <div key={'Timesheet-Row-Day-' + idx.toString()}>
            {item?.hours
              ? item?.hours
              : item?.isSick
              ? 'Sick'
              : item?.isVacation
              ? 'Vacation'
              : '-'}
          </div>
        )
      })}
    </div>
  )
}

const Timesheet = () => {
  const dispatch = useAppDispatch()
  const allTimesheets = useAppSelector(timesheetSelectors.selectAll)
  useEffect(() => {
    dispatch(loadTimesheets())
  }, [dispatch])

  const handleClickTimesheet = async (id: string) => {
    const SetTimesheetApproved = async (id: string) => {
      // Get the user's token from aws-amplify
      const token = await Auth.currentSession()
      const idToken = token.getIdToken().getJwtToken()

      const headers = {
        Authorization: idToken,
        'Content-Type': 'application/json',
      }

      const response = await fetch(
        `https://4ah6p2plk7.execute-api.us-east-1.amazonaws.com/dev/timesheets/${id}`,
        {
          method: 'PATCH',
          headers,
          body: JSON.stringify({ state: 'completed' }),
        }
      )

      console.log(await response.json())
    }

    let timesheet = allTimesheets.find((item: any) => item.id === id)
    console.log(timesheet)
    if (timesheet) {
      const approved = await SetTimesheetApproved(id)
    }
  }

  return (
    <>
      <div className="m-auto mx-auto grid min-w-fit max-w-7xl grid-cols-8 place-self-center justify-self-center rounded-md text-xs md:mb-2 md:border md:text-sm lg:mb-2 lg:border lg:text-base">
        <div className="col-span-8">
          <TimesheetHeaders />
        </div>
        <div className="col-span-8">
          {allTimesheets.map((item, idx: number) => {
            if (item.days && item.start) {
              // console.log(item)
              return (
                <TimesheetRow
                  key={`timesheet-${idx.toString()}`}
                  start={item.start}
                  days={item.days}
                  onClick={() => handleClickTimesheet(item.id)}
                />
              )
            } else {
              return null
            }
          })}
        </div>
      </div>
    </>
  )
}

export default Timesheet
