import useRequireAuthentication from '../../features/auth/hooks/useRequireAuthentication'

const Signature = () => {
  useRequireAuthentication()
  return (
    <div className="flex flex-col gap-8">
      <h2>Signature</h2>
    </div>
  )
}

export default Signature
