import { Auth } from 'aws-amplify'

const baseUrl = `https://pzm80umfn2.execute-api.us-east-1.amazonaws.com/dev/admin/`
const method = 'GET'

export const getUsers = async (pagination?: string, limit?: number) => {
  const token = await Auth.currentSession()
  let url = new URL(baseUrl)

  if (pagination) {
    url.searchParams.append('PaginationToken', pagination)
  }
  if (limit) {
    url.searchParams.append('Limit', limit.toString())
  }

  const headers = {
    Authorization: `${token.getIdToken().getJwtToken()}`,
    'Content-Type': 'application/json',
  }

  const response = await fetch(url.toString(), {
    method,
    headers,
  })

  if (response.status !== 200) {
    throw new Error('Could not get users')
  }

  return await response.json()
}
