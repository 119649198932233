import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner'

const Logout = () => {
  const nav = useNavigate()

  useEffect(() => {
    const tryLogout = async () => {
      try {
        return await Auth.signOut()
      } catch (err) {
        console.log('error signing out: ', err)
      }
    }

    toast.promise(
      tryLogout,
      {
        pending: {
          render: () => 'Logging out...',
          type: 'info',
          icon: <Spinner />,
        },
        success: {
          render: () => 'Logged out',
          type: 'success',
          icon: <AiOutlineCheckCircle className="text-tik-500" />,
        },
        error: {
          render: () => 'Error logging out',
          type: 'error',
          icon: <AiOutlineCloseCircle className="text-red-500" />,
        },
      },
      {
        position: 'bottom-center',
      }
    )
    nav('/login')
  }, [nav])

  return (
    <>
      <div className="flex flex-col gap-8">
        <h2 className="text-center">Logging out...</h2>
        <Spinner />
      </div>
    </>
  )
}

export default Logout
