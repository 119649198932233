import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { RiCheckDoubleFill } from 'react-icons/ri'
import { ToolTip } from '../../../components/Tooltip'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  nextMonth,
  prevMonth,
  selectCalendarDate,
  selectIsSelectMultiple,
  toggleSelectMultiple,
} from '../calendarSlice'
import CalendarNavButton from './CalendarNavButton'

import useTimedDisable from '../../../hooks/useTimedDisable'

const Nav = ({ multi = false }: { multi?: boolean }) => {
  const dispatch = useAppDispatch()
  const calendarDate = useAppSelector(selectCalendarDate)
  const isMulti = useAppSelector(selectIsSelectMultiple)

  const handleClickPrevMonth = () => {
    dispatch(prevMonth())
  }

  const handleClickNextMonth = () => {
    dispatch(nextMonth())
  }

  const handleToggleMulti = (e: any) => {
    e.preventDefault()
    dispatch(toggleSelectMultiple())
  }

  return (
    <>
      {multi && <div></div>}
      <CalendarNavButton
        onClick={handleClickPrevMonth}
        tooltip="Previous Month"
      >
        <AiOutlineArrowLeft className="mx-auto" />
      </CalendarNavButton>
      <h1 className="col-span-2 select-text">
        {new Date(calendarDate).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: undefined,
        })}
      </h1>
      <CalendarNavButton onClick={handleClickNextMonth} tooltip="Next Month">
        <AiOutlineArrowRight className="mx-auto" />
      </CalendarNavButton>
      {multi && (
        <CalendarNavButton
          onClick={handleToggleMulti}
          tooltip="Toggle Multi-Select"
        >
          {isMulti ? (
            <RiCheckDoubleFill className="pointer-events-none mx-auto text-tik-500" />
          ) : (
            <RiCheckDoubleFill className="pointer-events-none mx-auto" />
          )}
        </CalendarNavButton>
      )}
      <ToolTip />
    </>
  )
}

export default Nav
